import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { Anchor, AnchorProps } from "grommet/components/Anchor";

type IProps = LinkProps &
  AnchorProps &
  Omit<JSX.IntrinsicElements["a"], "color">;

export const AnchorLink: React.FC<IProps> = (props) => {
  return (
    <Anchor
      as={({ colorProp, hasIcon, hasLabel, focus, ...p }) => <Link {...p} />}
      {...props}
    />
  );
};
