import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Box, Spinner } from "grommet";

import { ParkingLayout } from "./UI/Layout/ParkingLayout";

const ParkingBookingPage = React.lazy(
  () => import("./Pages/ParkingBooking/ParkingBookingPage")
);
const ParkingCampaignListPage = React.lazy(
  () => import("./Pages/ParkingCampaignList/ParkingCampaignListPage")
);
const ParkingCampaignEditPage = React.lazy(
  () => import("./Pages/ParkingCampaignEdit/ParkingCampaignEditPage")
);
const ParkingInspectionPage = React.lazy(
  () => import("./Pages/ParkingInspection/ParkingInspectionPage")
);
const ParkingBookingTermsPage = React.lazy(
  () => import("./Pages/ParkingBookingTerms/ParkingBookingTermsPage")
);
const ParkingBookingConfirmationPage = React.lazy(
  () =>
    import("./Pages/ParkingBookingConfirmation/ParkingBookingConfirmationPage")
);
const TravelAgencyListPage = React.lazy(
  () => import("./Pages/TravelAgencyList/TravelAgencyListPage")
);
const TravelAgencyEditPage = React.lazy(
  () => import("./Pages/TravelAgencyEdit/TravelAgencyEditPage")
);

export const App: React.FunctionComponent = () => {
  return (
    <Suspense
      fallback={
        <Box height="600px" justify="center" align="center">
          <Spinner size="medium" />
        </Box>
      }
    >
      <Routes>
        <Route
          path="/"
          element={
            <ParkingLayout mode="basic">
              <ParkingBookingPage />
            </ParkingLayout>
          }
        />
        <Route
          path="/parkeringsbekraftelse"
          element={
            <ParkingLayout mode="basic">
              <ParkingBookingConfirmationPage />
            </ParkingLayout>
          }
        />
        <Route
          path="/villkor"
          element={
            <ParkingLayout mode="basic">
              <ParkingBookingTermsPage />
            </ParkingLayout>
          }
        />
        <Route
          path="kampanjer"
          element={
            <ParkingLayout mode="admin">
              <ParkingCampaignListPage />
            </ParkingLayout>
          }
        />
        <Route
          path="kampanjer/:campaignId"
          element={
            <ParkingLayout mode="admin">
              <ParkingCampaignEditPage />
            </ParkingLayout>
          }
        />
        <Route
          path="rondering"
          element={
            <ParkingLayout mode="admin">
              <ParkingInspectionPage />
            </ParkingLayout>
          }
        />
        <Route
          path="resebolag"
          element={
            <ParkingLayout mode="admin">
              <TravelAgencyListPage />
            </ParkingLayout>
          }
        />
        <Route
          path="resebolag/:agencyId"
          element={
            <ParkingLayout mode="admin">
              <TravelAgencyEditPage />
            </ParkingLayout>
          }
        />
        <Route path="*" element={<div>404: Sida finns ej</div>} />
      </Routes>
    </Suspense>
  );
};

export default App;
