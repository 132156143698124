import { useEffect, useMemo, useState } from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";

import fragmentMatcher from "src/codegen-fragmentmatcher";
import { StrictTypedTypePolicies } from "src/codegen-types";

export const PortalApolloProvider: React.FunctionComponent = (props) => {
  const [cachePersisted, setCachePersisted] = useState(false);

  const { httpLink, errorLink, cache } = useMemo(() => {
    const typePolicies: StrictTypedTypePolicies = {
      ParkingQuery: { keyFields: [] },
      KlarnaQuery: { keyFields: [] },
    };

    const httpLink = createHttpLink({
      uri: "/api/graphql",
    });

    const errorLink = onError(({ graphQLErrors }) => {
      if (
        graphQLErrors?.some(
          (error) => error.extensions["number"] === "authorization"
        )
      ) {
        if (process.env.NODE_ENV === "development") {
          window.location.replace(
            `https://localhost:7293/api/user/login?returnUrl=http://localhost:3000${window.location.pathname}`
          );
        } else {
          window.location.replace(
            `/api/user/login?returnUrl=${window.location.pathname}`
          );
        }
      }
    });

    const cache = new InMemoryCache({
      possibleTypes: fragmentMatcher.possibleTypes,
      typePolicies: typePolicies,
    });

    return { httpLink, errorLink, cache };
  }, []);

  useEffect(() => {
    const persistCachAsync = async () => {
      await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
      });

      setCachePersisted(true);
    };

    persistCachAsync();
  }, [cache]);

  if (!cachePersisted) {
    return null;
  }

  const client = new ApolloClient({
    cache: cache,
    link: ApolloLink.from([errorLink, httpLink]),
    defaultOptions: { watchQuery: { fetchPolicy: "cache-and-network" } },
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
