import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import "dayjs/locale/sv";

import { PortalApolloProvider } from "./Providers/PortalApolloProvider";
import { PortalThemeProvider } from "./Providers/PortalThemeProvider";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";

dayjs.extend(localizedFormat);
dayjs.locale("sv");

ReactDOM.render(
  <React.StrictMode>
    <PortalThemeProvider>
      <PortalApolloProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PortalApolloProvider>
    </PortalThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
