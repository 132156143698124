import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames/bind";
import { Box, Footer, Header, Image, Nav, Text } from "grommet";

import logo from "src/Assets/oa-logo.svg";

import { AnchorLink } from "../Components/AnchorLink/AnchorLinkComponent";

import "./ParkingLayout.scss";

interface IProps {
  mode: "basic" | "admin";
}
const blockClassName = "parking-layout";

export const ParkingLayout: React.FunctionComponent<IProps> = (props) => {
  const location = useLocation();

  const headerMenu = useMemo(() => {
    if (props.mode === "basic") {
      return null;
    }

    return (
      <Nav direction="row">
        <AnchorLink
          className={classNames(`${blockClassName}__link`, {
            [`${blockClassName}__link--is-active`]:
              location.pathname.startsWith("/kampanjer"),
          })}
          to="/kampanjer"
          label="Kampanjer"
          color="brand_anchor"
        />
        <AnchorLink
          className={classNames(`${blockClassName}__link`, {
            [`${blockClassName}__link--is-active`]:
              location.pathname.startsWith("/resebolag"),
          })}
          to="/resebolag"
          label="Resebolag"
          color="brand_anchor"
        />
        <AnchorLink
          className={classNames(`${blockClassName}__link`, {
            [`${blockClassName}__link--is-active`]:
              location.pathname.startsWith("/rondering"),
          })}
          to="/rondering"
          label="Rondering"
          color="brand_anchor"
        />
      </Nav>
    );
  }, [props.mode, location]);

  return (
    <div className={blockClassName}>
      <Header
        background="white"
        pad="small"
        className={`${blockClassName}__header`}
        border="bottom"
      >
        <Box direction="row" align="center" gap="small">
          <AnchorLink to="/">
            <Image src={logo} width={"225px"} />
          </AnchorLink>
        </Box>
        {headerMenu}
      </Header>
      <div className={`${blockClassName}__main`}>{props.children}</div>
      <div className={`${blockClassName}__footer`}>
        <Footer background="brand_secondary" justify="center" pad="small">
          <Box alignContent="center">
            <Text textAlign="center" size="small">
              Örebro Airport | Org.nr. 556241-6353 |{" "}
              <a href="tel:+46 (0)19 30 70 00">+46 (0)19 30 70 00</a> |{" "}
              <a href="mailto:parking@orebroairport.se">
                parking@orebroairport.se
              </a>
            </Text>
            <Text textAlign="center" size="small">
              © {new Date().getFullYear()} Copyright Örebro Läns Flygplats AB
            </Text>
          </Box>
          <Image src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg" />
        </Footer>
      </div>
    </div>
  );
};
