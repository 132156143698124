import { Grommet, ThemeType } from "grommet";

const theme: ThemeType = {
  global: {
    colors: {
      brand: "#ff5825",
      brand_secondary: "#d4ece9",
      brand_highlight: "#ff5825",
      brand_anchor: "#444444",
      focus: "#ff5825",
      white: "#fff",
    },
    font: {
      family: "Roboto",
      size: "18px",
      height: "20px",
    },
  },
  textInput: {
    extend: `background-color: #fff`,
  },
  button: {
    color: "#fff",
  },
};

export const PortalThemeProvider: React.FunctionComponent = (props) => {
  return <Grommet theme={theme}>{props.children}</Grommet>;
};
